<template>
  <div>
    <div class="contain">
      <div class="contain1_1">
        <img src="@/assets/image/icon25.png" alt="" class="leftimg" />
        <div class="nav">
          <div
            class="nav_item"
            v-for="(item, index) in breadcrumb"
            :key="index"
            @click="goPage(item)"
          >
            <div>{{ item.name }}</div>
            <div v-if="breadcrumb.length - 1 != index">/</div>
          </div>
        </div>
      </div>
      <div class="contain1_1_line"></div>
      <div
        class="list_item"
        v-for="(item, index) in list"
        :key="index"
        @click="jumpDetail(item)"
      >
        <div class="list_item_title">
          <img src="@/assets/image/icon39.png" alt="" />{{ item.title }}
        </div>
        <div class="list_item_time">
          <div class="">
            <img src="@/assets/image/icon37.png" alt="" />{{
              item.updated_at.split("T")[0]
            }}&nbsp;{{ item.updated_at.split("T")[1].split("Z")[0] }}
          </div>
          <div class="">
            <img src="@/assets/image/icon38.png" alt="" />{{
              item.view_count || 0
            }}
            浏览
          </div>
        </div>
        <div class="list_item_line"></div>
      </div>
      <div class="pagination_contain">
        <div>共{{ total }}项数据</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="getNoticeList"
        >
        </el-pagination>
      </div>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import { get } from "/utils/request";
export default {
  data() {
    return {
      breadcrumb: [
        { id: "", name: "首页" },
        { id: 2, name: "公告列表" },
      ],
      list: [1, 1, 1, 1, 1],
      page: 1,
      size: 10,
      total: 0,
    };
  },
  created() {
    this.getNoticeList();
  },
  methods: {
    goPage(item) {
      console.log(item);
      if (item.name == "首页") {
        this.$router.replace("/");
        return;
      }
    },
    jumpDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/noticeDetail",
        query: {
          identifier: item.identifier,
        },
      });
    },
    getNoticeList(val) {
      this.page = val;
      get("/article/list", {
        page: this.page,
        size: 10,
        is_notice: 1,
      }).then((res) => {
        console.log(res);
        this.total = res.total;
        this.list = res.article;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 17px auto 50px;
  padding: 20px 20px 0;
  box-sizing: border-box;
  .contain1_1 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .leftimg {
      width: 28px;
      height: 28px;
    }
    .nav {
      display: flex;
      align-items: center;
      .nav_item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
        cursor: pointer;

        div:nth-child(1) {
          margin: 0 6px;
        }
      }
    }
  }
  .contain1_1_line {
    height: 1px;
    background: #f4f4f4;
  }
  .list_item {
    padding: 20px 0 0;
    box-sizing: border-box;
    cursor: pointer;
    .list_item_title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #333333;
      letter-spacing: 0.9px;
      margin-bottom: 6px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }
    }
    .list_item_time {
      display: flex;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        margin-right: 50px;
      }
      img {
        width: 28px;
        height: 28px;
      }
    }
    .list_item_line {
      height: 1px;
      background: #f4f4f4;
      margin-top: 20px;
    }
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>